import 'bootstrap';


window.addEventListener('load', function() {
  // **** アンカーリンクなどで使用 ****
  const scrollTop = new SmoothScroll('a[href*="#"]', {
    speed: 500,
    header: '#header', // 固定ヘッダーがある場合、その高さ分も込みで追従してくれる
    easing: 'linear',
    offset: 50
  });
  
  if ( window.location.hash ) {
    window.scrollTo(0, 0);
    scrollTop.animateScroll( document.querySelector(window.location.hash) );
  }
});